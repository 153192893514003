:root {
    --section-overlay: rgba(0, 0, 0, 0.7);
    --background-overlay: rgba(0, 0, 0, 0.2)
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.app {
    width: 100%;
    height: 100vh;
    background-position: center;
    background-size: cover;
}

.overlay {
    width: 100%;
    height: 100vh;
    background-color: var(--background-overlay);
}

.container {
    max-width: 800px;
    margin: auto;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 1rem;
}

/* Top  */

.section {
    width: 100%;
    padding: 1rem;
    border-radius: 0.4rem;
    color: white;
}

.section__inputs, .section__temperature {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--section-overlay);
}

.section__inputs>input {
    border: 0.8px solid white;
    border-radius: 0.4rem;
    background-color: transparent;
    color: white;
    padding: 0.5rem;
    font-size: 20px;
    font-weight: 200;
}

.section__inputs>input:focus {
    outline: none;
}

.section__inputs>button {
    padding: 10px 50px;
    border: none;
    border-radius: 00.4rem;
    font-size: 20px;
    font-weight: 500;
    background-color: white;
}

.section__inputs>button:hover {
    cursor: pointer;
    background-color: lightgray;
}

.icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.icon>h3 {
    font-size: 15px;
    font-weight: 200;
    text-transform: capitalize;
}

.temperature>h1 {
    font-size: 60px;
}